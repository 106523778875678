<template>
  <div class="container">
    <div class="content">
      <div class="login_top">
        <div class='login_top_left'>{{$t('login.findPwsd')}}</div>
      </div>
      <div class="login_filed">
        <van-field v-model="loginForm.userName" :placeholder="$t('login.phoneNumber')" @change="getPhone"/>
        <van-field v-model="loginForm.verifyCode" @input="codeOnlyNumber" minlength='4' maxlength='6'
        :placeholder="$t('login.inputCode')" use-button-slot>
        <van-button slot="button" :class="codeDisabled ? 'disabled_style' : 'btn_style'" type="primary"
          @click="sendcodeHandle" :disabled="disabledCode">
          {{btnText}}
        </van-button>
      </van-field>
      </div>
      <van-button class="login_bn" :disabled="isDisableLoginBtn()" type="primary" @click="goNext">
        {{$t('login.next')}}</van-button>
      <Back :type='goType'></Back>
    </div>
  </div>
</template>
<script>
  import Back from "@/views/login/back.vue";
  import {
    checkCode,
    checkMobile,
  } from '../../utils/check'
  import {
    regCode,
    sendCode
  } from "@/api/meet"
  export default {
    components: {
      Back,
    },
    data() {
      return {
        goType:'forget',
        loginForm: {
          username: "",
          verifyCode: '',
        },
        btnText: this.$t('login.getCode'),
        timer: null,
        count: 60,
        codeDisabled:false,
        noSend:true,
      }
    },
    computed:{
      disabledCode(){
        return !checkMobile(this.loginForm.userName)
      },
    },
    methods: {
      codeOnlyNumber(value) {
        this.loginForm.verifyCode = value.replace(/[^\d]/g, "");
      },
        // 验证码倒计时
        getCode() {
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.codeDisabled = true;
          this.noSend = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count = this.count - 1;
              this.btnText = this.count + this.$t('login.resend');
            } else {
              this.codeDisabled = false;
              this.noSend = true;
              clearInterval(this.timer);
              this.btnText = this.$t('login.getCode');
              this.timer = null;
            }
          }, 1000);
        }
      },
    sendcodeHandle() {
        if (!checkMobile(this.loginForm.userName)) {
          this.$toast(this.$t('login.try'))
          return
        }
        if(!this.noSend) return
        sdk.auth.getVerifyCode(this.loginForm.userName)
      .then(res=> {
        this.$toast('发送成功');
        this.getCode();
      })
      .catch(error=>{
        this.$eventBus.$emit('network-error', error)
      })
      },
      // 清除倒计时
      destroyed() {
        if (this.timer) {
          clearInterval(this.timer);
        }
      },
      isDisableLoginBtn() {
        return !(this.loginForm.userName &&this.loginForm.verifyCode)
      },
      getPhone(event) {
        this.loginForm.username = event.target.value
      },
      async goNext() {
        if (!checkCode(this.loginForm.verifyCode)) {
          this.$toast(this.$t('login.failTextCode'))
          return
        }
        const data = {
          phone: this.loginForm.userName,
          code: this.loginForm.verifyCode
        }
        try {
          const resData = await regCode(data)
          if (resData) {
            this.$router.push({
              path: 'setNewPwd',
              query: {
                code: this.loginForm.verifyCode,
                username: this.loginForm.userName,
              }
            })
          } else {
          this.$toast(this.$t('login.codeFail'))
          }
        } catch (error) {
          console.log(error) // TODO:
        }
      },
    }
  }

</script>
<style lang="less" scoped>
  /* 登录部分输入框样式 */
  /deep/ .van-cell {
    padding: 10px 2px;
    margin-top: 18px;
    border-bottom: 0.1px solid #E5E5E5;

    &::after {
      right: 2px;
      left: 2px;
      border: none;
    }

    &:hover {
      border-bottom: 0.1px solid #1AB370;
    }
  }

  /deep/ .van-hairline--top-bottom::after {
    border: none;
  }

  .container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    .content {
      padding: 80px 64px;
      box-sizing: border-box;

      .login_top {
        display: flex;
        justify-content: space-between;
        line-height: 64px;

        &_left {
          font-size: 48px;
          color: #333333;
        }
      }

      .login_filed {
        margin-top: 60px;
        .public-code() {
          width: 198px;
          height: 64px;
          border-radius: 4px;
          font-size: 24px;
        }

          /deep/ .van-button--normal {
            padding: 0px;
          }

          .btn_style {
            background: rgba(255, 255, 255);
            border: 1px solid #FF8200;
            color: #FF8200;
            .public-code();
          }

          .disabled_style {
            border: none;
            background: #FFDFA3;
            color: #FFFFFF;
            .public-code();
          }
      }

      .login_bn {
        width: 622px;
        height: 88px;
        background: #1AB370;
        border-radius: 10px;
        border: none;
        margin: 80px 0 40px 0;
        font-size: 32px;
      }
    }
  }

</style>
